import Navbar from "react-bootstrap/Navbar"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./Menu.scss"

const Nav = ({ siteTitle }) => {
  return (
    <header>
      <Navbar
        className="nav-wrapper content animated fadeInDown"
        collapseOnSelect
        expand="md"
      >
        <Navbar.Brand>
          <Link to="/">
            <img alt={siteTitle} src="/logo/color.svg" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="nav-menu">
          <i class="icon ion-ios-menu"></i>
        </Navbar.Toggle>
        <Navbar.Collapse id="nav-menu">
          <Link to="/portfolio" activeStyle={{ color: "var(--gray2)" }}>Portfolio</Link>
          <Link to="/about" activeStyle={{ color: "var(--gray2)" }}>About Me</Link>
          <Link to="/contact" activeStyle={{ color: "var(--gray2)" }}>Contact</Link>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav
