import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import styles from "./Footer.scss"

const Footer = ({ children }) => {
  const { contentfulPerson } = useStaticQuery(graphql`
    query {
      contentfulPerson {
        name
        linkedIn
        twitter
      }
    }
  `)
  return (
    <>
      <footer>
        <div className="row wrapper">
          <div className="col-md-4"></div>
          <div className="content col-md-8 animated fadeIn">
            <h2>
              Let's Talk <br /> —{" "}
            </h2>
            <h3>
              Looking for a freelance designer, grow your in-house design team,
              <br /> or simply want to chat about design?
            </h3>
            <div class={classNames(styles.contentWrapper, "row animated fadeIndown")}>
              <a href="/contact" className="icon ion-ios-send">
                Contact
              </a>
              <a
                href={contentfulPerson.twitter}
                target="_blank"
                className="icon ion-logo-twitter"
              >
                Follow
              </a>
              <a
                href={contentfulPerson.linkedIn}
                target="_blank"
                className="icon ion-logo-linkedin"
              >
                Connect
              </a>
            </div>
            <div class="row animated fadeInDown">
              Copyright {new Date().getFullYear()}
              <img
                src={"/Logo/color.svg"}
                alt="logo icon"
                width={24}
                margin-bottom={0}
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer
